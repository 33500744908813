import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "../../../../services/observables/user.service";
import {User} from "../../../../models/user.interface";
import {LoadingService} from "../../../../services/observables/loading.service";
import {InvoiceService} from "../../../../services/observables/invoice.service";
import {Invoice} from "../../../../models/invoice.interface";
import {firstValueFrom, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {LoggingService} from "../../../../services/logging.service";
import {WarningService} from "../../../../services/warning.service";
import {WarningModalComponent} from '../../../shared/components/warning-modal/warning-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

declare let jQuery: any;
declare let window: any;

@Component({
  selector: 'jet-invoice-ups',
  templateUrl: './invoice-ups.component.html',
  styleUrls: ['./invoice-ups.component.scss']
})
export class InvoiceUpsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('iframe') iframe: ElementRef;

  public iframeSrc: SafeResourceUrl;
  public iframeLoading = true;
  private ngUnsubscribe = new Subject();
  private bsModalRef: BsModalRef;
  private showingModal = false;
  private shippingMatches = true;
  private invoice: Invoice;

  public iframeParams: {
    isComplete: boolean;
    path: string | null,
    token: string | null,
    employeeId: number | null,
    invoiceId: number | null,
    customerId: number | null,
  };

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private userService: UserService,
    private loadingService: LoadingService,
    private invoiceService: InvoiceService,
    private logService: LoggingService,
    private warningService: WarningService,
    private bsModalService: BsModalService,
  ) {

    this.iframeParams = {
      path: '/Internal/Managing/CRMS/STM/GetRatesUPS.aspx',
      isComplete: false,
      token: null,
      employeeId: null,
      invoiceId: null,
      customerId: null,
    };
  }

  iframeCheck(iframeCheckCount: number) {

    iframeCheckCount++;

    if (!this.iframeLoading) {
      return;
    }

    if (typeof this.iframe !== 'undefined') {
      let iframeDoc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
      let element = iframeDoc.getElementById('upsgrRate_divRating');

      if (element) {
        return;
      }
    }

    if (iframeCheckCount > 15) {
      this.logService.logger.debug('iframe-reload', {
          'iframe': this.iframe.nativeElement.src,
          'safeUrl': this.iframeSrc['changingThisBreaksApplicationSecurity'],
        }
      )

      this.iframeSrc = undefined;
      iframeCheckCount = 0;
      this.loadIframe();
    }

    setTimeout(() => {
      this.iframeCheck(iframeCheckCount);
    }, 100);
  }

  ngOnInit() {
    this.invoice = this.invoiceService.currentInvoice();
    this.setUpIframeListener();

    this.iframeCheck(0);

    jQuery(window).on('message onmessage', (event) => {
      const page = event.originalEvent.data.page;
      if (page === 'ups' || page === 'placeholder') {
        jQuery('#ups-iframe').height(event.originalEvent.data.height);
        this.iframeLoading = false;
      }
    });

    this.route.queryParams.pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((params: any) => {

      this.iframeParams.invoiceId = params.invoice_id;
      this.iframeParams.customerId = params.customer_id;

      this.loadIframe();
    });

    this.userService.getResource().pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe((user: User) => {

      if (user === null || user.relationships === null) {
        return;
      }

      this.iframeParams.employeeId = user.relationships.employee.attributes.EmployeeID;
      this.iframeParams.token = user.attributes.pentad_token;

      this.loadIframe();

      // This code below is not working. It is getting an error in the console.
      // ERROR TypeError: Cannot read property 'getElementById' of undefined
      // let confirmShipmentButton = jQuery('#ups-iframe').document.getElementById('upsgrRate_btnConfirmShipment');

      // This is not working because hasTrackingNumber does not return a function so it is instead running immediately.
      // confirmShipmentButton.on('click', this.hasTrackingNumber())
    });
  }

  ngAfterViewInit(): void {
    const element = document.querySelector('#ups-iframe');
    this.invoiceService.upateIframe(element);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

   onLoad(event) {
    let iframeDocBody = event.target.contentWindow.document.body.innerHTML;
    if (iframeDocBody.indexOf('Server Error') > -1) {
      this.logService.logger.debug('iframe', {
          'iframe': event.currentTarget.src,
          'safeUrl': this.iframeSrc['changingThisBreaksApplicationSecurity'],
          'iframeHtml': event.currentTarget.contentDocument.body.innerHTML
        }
      )
      this.warningService.openWarningModal(event.target.contentWindow.document.getElementsByTagName("H2")[0].innerText);
    }
  }

  private loadIframe() {

    for (let key in this.iframeParams) {
      if (this.iframeParams[key] === null) {
        return;
      }
    }

    this.iframeParams.isComplete = true;

    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.iframeParams.path
      + '?EmpID=' + this.iframeParams.employeeId
      + '&InvoiceID=' + this.iframeParams.invoiceId
      + '&CustomerID=' + this.iframeParams.customerId
    );
  }

  private hasTrackingNumber() {
    let trackingNumber = "";
    let invoice = this.invoiceService.getInvoice(this.iframeParams.invoiceId).pipe(take(1));
    invoice.subscribe(x => trackingNumber = x.attributes.FreightTrackingNumber)
    if (trackingNumber === "") {
      alert("Something went wrong with confirming shipment.")
    }
  }

  setUpIframeListener() {
    jQuery('#invUps').on('mouseenter', () => {
      this.compareInvoices();
    });
  }

  async promptUserForSave() {
    this.showingModal = true;

    if (this.showingModal) {
      const doUpdate = await this.showModal();

      if (doUpdate) {
        await this.saveInvoice();
        window.location.reload();
      }
      this.showingModal = false;
    }
  }

  async showModal() {
    const initialState = {
      enableConfirm: true,
      warnings: ['Please save invoice changes before generating UPS Rates.'],
      title: 'Save Invoice?',
      confirmMessage: 'Confirm',
    };

    this.bsModalRef = this.bsModalService.show(WarningModalComponent, {class: 'modal-lg', initialState});

    return (await firstValueFrom(this.bsModalRef.content.onClose.pipe(take(1))))['confirm'];
  }

  private saveInvoice() {
    return new Promise((resolve, reject) => {
      this.invoiceService.updateInvoice().pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      })
      ;
    });
  }

  private async compareInvoices() {
    if (!this.invoice) {
      return;
    }

    this.invoiceService.fetchInvoice(+this.invoice.id).subscribe(inv => {
      this.shippingMatches = JSON.stringify(this.invoice.relationships.shipToAddress) === JSON.stringify(inv.relationships.shipToAddress);

      if (this.shippingMatches) {
        return;
      }

      this.promptUserForSave();
    });
  }
}
